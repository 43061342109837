import React from "react";
import "./pdf.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function PDFView() {
  useDocumentTitle('PDF View');

  return (
    <iframe src="https://drive.google.com/file/d/1PGUvSouE5nyBk91m2FNWufSyyBrgVsS2/preview" title="PDF View" className="pdf-view"></iframe>
  );
}
