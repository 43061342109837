import React from "react";
import ReactGA from "react-ga4";
import "./outreach.scss";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Outreach() {
  useDocumentTitle("Outreach Oasis");
  ReactGA.send({ hitType: "pageview", page: "/outreach" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/outreach-bg.jpg)", backgroundPosition: "center" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Outreach Oasis</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
        <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">Outreach Ministry</h2>
          </header>
          <div className="row gy-4">
            <div className="col">
              <p>
                Get stoked! Every three months, our outreach ministry packs up
                and heads out to help those in need. We're talking orphanages
                and old age homes, feeding kids, and even a Toys for Tots drive.
                It's a heartwarming adventure, and you're invited to join us.
                Let's make a difference together!
              </p>
            </div>
            <div className="col">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/LhBNwlpKvAE?si=UM-kd4qE4PbikyWo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
