import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import "./news.scss";
import useDocumentTitle from "../../components/useDocumentTitle";
import Loader from "../../components/loader";
import { map } from "lodash";
import moment from "moment";

export default function News() {
  useDocumentTitle("News");
  ReactGA.send({ hitType: "pageview", page: "/news" });
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/newsPageData`;

  const [data, setData] = useState({ videos: [], events: [] });
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch(dataUrl)
      .then((response) => response.json())
      .then((homePageData) => {
        setData(homePageData);
        setLoading(false);
      });
  }, [dataUrl]);

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/hero-bg-3.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">News</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light py-5" id="news">
        <div className="container">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">Whats happening</p>
            <h2 className="text-uppercase">Latest News</h2>
          </header>
          <div className="row align-items-center">
            {isLoading && <div className="col-auto mx-auto"><Loader></Loader></div>}
            {!isLoading && map(data, (newsItem, index) => (
              <div
                key={`${newsItem.datePublished}-${index}`}
                className="col-md-12 mb-4"
              >
                <script type="application/ld+json">
                  {JSON.stringify({
                    "@context": "https://schema.org",
                    "@type": "NewsArticle",
                    headline: newsItem.headline,
                    image: [newsItem.image],
                    datePublished: newsItem.datePublished,
                    dateModified: newsItem.datePublished,
                    author: [
                      {
                        "@type": "Organization",
                        name: "Love of Christ CSI",
                        url: "https://loveofchristcsichurch.org/",
                      },
                    ],
                  })}
                </script>
                <div className="row gy-4">
                  <div className="col-lg-2 text-center">
                    <div className="d-flex flex-column align-items-center justify-content-center justify-content-lg-start">
                      <p className="text-gray text-l h1 mb-0">
                        {moment(newsItem.datePublished).format("DD")}
                      </p>
                      <div className="ms-2">
                        <h5 className="mb-0">
                          {moment(newsItem.datePublished).format("MMMM")}
                        </h5>
                        <p className="text-small mb-0 text-muted">
                          {moment(newsItem.datePublished).format("dddd")}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 text-left">
                    <p className="text-serif mb-4 text-muted">
                      {newsItem.headline}
                    </p>
                    <small>{newsItem.author}</small>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
