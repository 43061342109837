import { useRef, useEffect } from "react";

function useDocumentTitle(title, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  const setTitle = (title) => {
    document.title = `${title} | Love of Christ CSI Church of Dallas | Church of South India`;
  };

  useEffect(() => {
    setTitle(title);
  }, [title]);

  useEffect(
    () => () => {
      if (!prevailOnUnmount) {
        setTitle(defaultTitle.current);
      }
    },
    [prevailOnUnmount]
  );
}

export default useDocumentTitle;
