export default function Loader() {
    return <div className="app-loader">
        <div className="spinner"></div>
        <img
              className="round-header-image"
              src="img/loccsi.svg"
              alt="Love of Christ CSI"
              width="100%"
            />
    </div>
}