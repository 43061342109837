import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";

import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/captions.css";

import "./gallery.scss";
import { map } from "lodash";
import Loader from "../../components/loader";
import useDocumentTitle from "../../components/useDocumentTitle";

export default function Gallery() {
  useDocumentTitle("Gallery");
  const dataUrl = `${process.env.REACT_APP_API_BASE_URL}/galleryPageData`;

  const [images, setImages] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const breakpoints = [3840, 2400, 1080, 640, 384, 256, 128, 96, 64, 48];
    setLoading(true);
    fetch(dataUrl)
      .then((response) => response.json())
      .then((imageData) => {
        setImages(
          map(imageData, (image) => ({
            src: image.url,
            title: image.caption,
            width: image.width,
            height: image.height,
            srcSet: breakpoints.map((breakpoint) => {
              const height = Math.round(
                (image.height / image.width) * breakpoint
              );
              return {
                src: `${image.url}=h${height}-no`,
                width: breakpoint,
                height,
              };
            }),
          }))
        );
        setLoading(false);
      });
  }, [dataUrl]);

  const [index, setIndex] = useState(-1);

  return (
    <>
      <script type="application/ld+json">
        {JSON.stringify(
          map(images, (image) => ({
            "@context": "https://schema.org/",
            "@type": "ImageObject",
            contentUrl: image.src,
            license: "https://loveofchristcsichurch.org/license",
            acquireLicensePage:
              "https://loveofchristcsichurch.org/how-to-use-my-images",
            creditText: "Love of Christ CSI",
            creator: {
              "@type": "Organization",
              name: "Love of Christ CSI Church",
              url: "https://loveofchristcsichurch.org",
            },
            copyrightNotice: "Love of Christ CSI",
          }))
        )}
      </script>
      <section
        className="bg-cencer bg-cover"
        style={{
          background: "url(img/gallery-bg.jpg)",
          backgroundPosition: "center",
        }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Photo Gallery</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5 text-center">
          <div className="row text-center gy-4">
            <div className="col-xs-12">
              {isLoading && (
                <div className="loader-photo">
                  <Loader></Loader>
                </div>
              )}
              {!isLoading && (
                <>
                  <PhotoAlbum
                    photos={images}
                    layout="masonry"
                    targetRowHeight={150}
                    componentsProps={(containerWidth) => ({
                      imageProps: {
                        loading: (containerWidth || 0) > 600 ? "eager" : "lazy",
                      },
                    })}
                    onClick={({ index }) => setIndex(index)}
                  />

                  <Lightbox
                    slides={images}
                    open={index >= 0}
                    index={index}
                    close={() => setIndex(-1)}
                    // enable optional lightbox plugins
                    plugins={[
                      Fullscreen,
                      Slideshow,
                      Thumbnails,
                      Zoom,
                      Captions,
                    ]}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
