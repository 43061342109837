import React, { useEffect } from "react";

import "./faq.scss";
import { map } from "lodash";
import useDocumentTitle from "../../components/useDocumentTitle";
import { useLocation } from "react-router-dom";

export default function FAQs() {
  const FAQs = [
    {
      question: "What is Love of Christ CSI Church and what is their belief?",
      answer:
        "Love of Christ is central to Christian faith and is often emphasized on Cor 13:4-8 in the Bible. It is seen as a selfless, forgiving, and transformative love that serves as a model for believers to emulate in their relationships with others.",
    },
    {
      question:
        "Is the Love of Christ CSI Church associated to any christian denomination?",
      answer:
        '<p>Love of Christ CSI Church is a member church of the Church of South India (CSI). We are regonized as a church under the CSI Diaspora Diocese on February 10, 2024.</p><p>The Church of South India (CSI) is a Protestant Christian denomination that originated in 1947 through the union of several Protestant churches in South India. CSI believes in the core tenets of Christianity, including the Trinity (Father, Son, and Holy Spirit), the authority of the Bible, the divinity of Jesus Christ, salvation through faith in Christ, and the importance of Christian community and worship. The denomination is known for its inclusivity, bringing together people from various Protestant traditions. The specific beliefs and practices within the Church of South India may vary, but they generally align with the mainstream protestant christianity. <a href="https://csi1947.com/about/history" target="_blank" rel="noreferrer">Read more about CSI here</a></p>',
    },
    {
      question:
        "Are there other CSI (Church of South India) churches in Dallas / Fortworth Metroplex area?",
      answer:
        '<a href="https://loveofchristcsichurch.org/">Love of Christ CSI Church, Dallas, TX</a> and <a href="https://www.csicod.org/" target="_blank" rel="noreferrer" >CSI Congregation of Dallas, Garland, TX</a> are the only two <abbr title="Church of South India">CSI</abbr> Churches in the <abbr title="Dallas/Forth Worth">DFW</abbr> Metroplex. There might be other south indian regional churches but they are not under the Church of South India or the CSI Diaspora Diocese.',
    },
    {
      question: "What time is your worship service?",
      answer:
        'Our Sunday Worship Service time is from 09:00 AM to 10:30 AM every Sunday. We have Holy Communion service on the 1<sup>st</sup> and 3<sup>rd</sup> Sundays of every month. We also have special services for various occations and recoment checking our <a href="/events">events page</a> for more details.',
    },
    {
      question: "What kind of programs do you offer for children?",
      answer:
        '<p>Children are a special part of our congregation and we value them as full participants in the Love of Christ CSI Church. Children will attend the sunday worship service and will then proceed to their own time of singing, prayer and Bible learning for sunday class after the service at 11:00 AM every Sunday.</p><p>Also, our children\'s program is catered for various age groups and is rich with spritual learnings. Learn more about our <a href="/kids">Kingdom Kids Ministry</a></p>',
    },
    {
      question: "What is the setup and order of your Sunday Service?",
      answer:
        "<p>We follow the CSI Almanac and the CSI Common Book of Worship for our order of service. Also, we sing songs from the Celebration Hymnal and also South Indian Regional songs in Tamil, Malayalam, Telugu and Kannada. The sermon focuses on relevant and practical applications of biblical truths, encouraging personal growth and spiritual development and follows the theme established by CSI Almanac.</p><p>We participate in the sacred observance of Holy Communion as a significant aspect of our worship service.</p>",
    },
    {
      question: "Is Communion open to visitors?",
      answer:
        "<p>Absolutely! At Love of Christ CSI Church, we extend an open invitation to all our visitors, warmly welcoming them to take part in the sacred communion experience. Communion is a beautiful and inclusive sacrament where we commemorate the Lord's Supper, and we believe that everyone, regardless of their visitor status, is invited to join in this meaningful and spiritual practice.</p><p>Come, share in this time of reflection, unity, and celebration of Christ's sacrifice with us. Whether you are a long-time member or a first-time visitor, you are an integral part of our worshiping community.</p><p> If you have any questions or would like any further guidance, feel free to reach out to our pastoral team or one of our friendly ushers during the service. We look forward to having you join us at the communion table.</p>",
    },
    {
      question: "Who are your ministers?",
      answer:
        'Visit the <a href="/pastors">Pastors page</a> to learn about our ministers.',
    },
    {
      question:
        "Do you offer any adult education/spiritual growth opportunities?",
      answer:
        "<p> At Love of Christ CSI Church, we are committed to fostering continuous growth in faith and understanding. Our adult education and spiritual growth initiatives are designed to provide enriching opportunities for individuals at every stage of their spiritual journey. Discover the diverse avenues available to deepen your understanding of God's Word and nurture your spiritual growth.</p><p> <strong>Weekly Prayer Meeting</strong></p><p> <strong>Men’s Fellowship</strong></p><p> <strong>Adult Worship and Music</strong></p>",
    },
    {
      question: "What kind of outreach is the church involved in?",
      answer:
        "<p> At Love of Christ CSI Church, we believe in extending the love of Christ beyond the walls of our sanctuary. Our outreach initiatives are a testament to our commitment to serve and make a positive impact in the wider community. Discover the diverse ways in which we actively engage in outreach, embodying the compassion and kindness modeled by Jesus.</p><p> <strong>Community Assistance Programs:</strong> <br /> Participate in our community assistance programs that aim to support those facing various challenges. From providing essential resources to offering a helping hand, we strive to make a tangible difference in the lives of individuals and families in need.</p><p> <strong>Global Missions:</strong> <br /> Engage in our global missions efforts, reaching out to communities beyond our immediate vicinity. Through mission trips, partnerships with international organizations, and support for global initiatives, we extend our reach to make a positive impact on a global scale.</p>",
    },
    {
      question: "How will I contribute financially?",
      answer:
        '<p> At Love of Christ CSI Church, your financial support plays a crucial role in sustaining our vibrant community, enabling impactful ministries, and fostering a welcoming space for worship, fellowship, and outreach. We appreciate your interest in contributing, and there are several convenient and secure ways to partner with us financially</p><p> There are multiple options for you to contribute to Love of Christ CSI Church. Please visit out <a href="/donation">Donation page</a></p>',
    },
    {
      question: "Whom do I contact if i have further questions?",
      answer:
        'Please send an email to <a href="mailto:welcome@loccsi.org">welcome@loccsi.org</a> with your questions. We are happy to answer any questions related to Love of Christ CSI. Also, check our <a href="/contacts">Contacts Page</a> for additional contact details.',
    },
  ];

  const { hash } = useLocation();
  useEffect(() => {
    if (hash) {
      setTimeout(
        () =>
          document.querySelector(hash)?.scrollIntoView({ behavior: "smooth" }),
        300
      );
    }
  }, [hash]);

  useDocumentTitle("FAQs");

  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/hero-bg-3.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">Questions</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          <header className="mb-4 text-center mb-5">
            <p className="text-serif mb-0 text-primary">
              Love of Christ CSI Church
            </p>
            <h2 className="text-uppercase">FAQs</h2>
          </header>
          <script type="application/ld+json">
            {JSON.stringify(
              map(FAQs, (FAQ) => ({
                "@context": "https://schema.org",
                "@type": "QAPage",
                mainEntity: {
                  "@context": "https://schema.org/",
                  "@type": "Question",
                  name: FAQ.question,
                  text: FAQ.question,
                  answerCount: 1,
                  datePublished: "2024-02-17T08:00:00Z",
                  author: "General",
                  acceptedAnswer: {
                    "@type": "Answer",
                    text: FAQ.answer,
                    upvoteCount: 100,
                    url: "https://loveofchristcsichurch.org/",
                    datePublished: "2024-02-17T08:00:00Z",
                    author: {
                      "@type": "Organization",
                      name: "Love of Christ CSI",
                      url: "https://loveofchristcsichurch.org/",
                    },
                  },
                },
              }))
            )}
          </script>
          <div className="row gy-4">
            <div className="col-xs-12">
              <div className="accordion" id="faq-questions">
                {map(FAQs, (FAQ, index) => (
                  <div className="accordion-item" key={index + 1} id={`faq-${index + 1}`}>
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${hash === `#faq-${index + 1}` ? '' : 'collapsed'}`}
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#faq_question_${index + 1}`}
                        aria-expanded="true"
                        aria-controls={`faq_question_${index + 1}`}
                      >
                        {index + 1}. {FAQ.question}
                      </button>
                    </h2>
                    <div
                      id={`faq_question_${index + 1}`}
                      className={`accordion-collapse collapse ${hash === `#faq-${index + 1}` ? 'show' : ''}`}
                    >
                      <div
                        className="accordion-body"
                        dangerouslySetInnerHTML={{ __html: FAQ.answer }}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
