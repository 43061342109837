import React from "react";
import ReactGA from "react-ga4";
import './nomatch.scss';
import useDocumentTitle from "../../components/useDocumentTitle";

export default function NoMatch() {
  useDocumentTitle('404');
  ReactGA.send({ hitType: "pageview", page: "/404" });
  return (
    <>
      <section
        className="bg-cencer bg-cover"
        style={{ background: "url(img/hero-bg-3.jpg)" }}
      >
        <div className="dark-overlay">
          <div className="overlay-content py-5 index-forward">
            <div className="container py-5 mt-5">
              <div className="row py-5 text-white text-center">
                <div className="col-lg-7 mx-auto">
                  <h1 className="text-xl">No Match</h1>
                  {/* <p className="lead">
                    Use this subpage for text, about us page, and more.
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="py-5" id="sermons">
        <div className="container py-5">
          
        </div>
      </section>
    </>
  );
}
