/* eslint jsx-a11y/anchor-is-valid: 0 */
import { useEffect } from "react";
import ReactGA from "react-ga4";

function Footer() {
  useEffect(() => {
    /* =====================================================
		SCROLL TOP BUTTON [SHOW & HIDE & CLICKING]
	===================================================== */
    const scrollTopBtn = document.querySelector(".scroll-top");
    if (scrollTopBtn) {
      scrollTopBtn.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo(0, 0);
      });

      window.addEventListener("scroll", function () {
        if (window.pageYOffset >= 300) {
          scrollTopBtn.classList.add("visible");
        } else {
          scrollTopBtn.classList.remove("visible");
        }
      });
    }
  }, []);

  const visitorSignupClicked = () => {
    ReactGA.event({
      category: "Engagement",
      action: "Visitor Signup Clicked"
    });
  }

  return (
    <>
      <a className="scroll-top" href="#">
        <i className="bi bi-chevron-up"></i>
      </a>
      <footer className="pt-5 text-white" style={{ background: "#111" }}>
        <div className="container pt-5">
          <div className="row mb-5 pb-5">
            <div className="col-md-6 col-sm-12">
              <a
                href="https://www.csi1947.com/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="mb-3 round-footer-image"
                  src="img/csi.png"
                  alt="Church of South India"
                  width="100"
                />
              </a>
              <a href="https://loveofchristcsichurch.org/">
                <img
                  className="mb-3 round-footer-image"
                  src="img/loccsi.svg"
                  alt="Love of Christ CSI Church"
                  width="100"
                />
              </a>
              <p className="text-small">
                Love of Christ CSI Church is one of the two CSI (Church of
                South India) churches in the DFW Metroplex.
              </p>
            </div>
            <div className="col-md-6 col-sm-12 text-end">
              <h5 className="mb-4 mt-3">Stay connected with us</h5>
              <p className="text-small">
                We invite you to take a moment and share your details with us.
                By filling out the form, you'll ensure that you stay connected
                with all the latest updates, events, and meaningful happenings
                at Love of Christ CSI Church. Let's journey together in
                faith, fellowship, and the warmth of community.
              </p>
              <div className="input-group" style={{ justifyContent: "end" }}>
                <a
                  className="btn btn-primary px-4"
                  href="https://loveofchristcsi.churchcenter.com/people/forms/610941"
                  data-open-in-church-center-modal="true"
                  onClick={() => visitorSignupClicked()}
                >
                  Fill out our form!
                </a>
              </div>
              <script type="application/ld+json">
                {JSON.stringify({
                  "@context": "https://schema.org",
                  "@type": "Organization",
                  name: "Love of Christ CSI Church",
                  url: "https://loveofchristcsichurch.org/",
                  sameAs: [
                    "https://www.facebook.com/loveofchristcsi",
                    "https://www.youtube.com/@loveofchristcsi",
                    "https://www.instagram.com/loveofchristcsi",
                    "https://twitter.com/loveofchristcsi"
                  ],
                })}
              </script>
              <div className="input-group" style={{ justifyContent: "end" }}>
                <ul className="social list-inline">
                  <li className="list-inline-item">
                    <a
                      href="https://www.facebook.com/loveofchristcsi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-facebook"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.youtube.com/@loveofchristcsi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-youtube"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://www.instagram.com/loveofchristcsi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-instagram"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a
                      href="https://twitter.com/loveofchristcsi"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="bi bi-twitter-x"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="py-4 border-top border-dark text-center">
            <div className="mb-0 text-muted">
              <ul className="nav justify-content-center">
                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about">
                    Who we are
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/history">
                    History
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about#mission">
                    Our Mission
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/about#vision">
                    Our Vision
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/pastors">
                    Our Pastors
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/kids">
                    Children's Ministry
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/outreach">
                    Outreach Ministry
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/events">
                    Upcoming Events
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/news">
                    Latest News
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/gallery">
                    Photo Gallery
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#sermons">
                    Latest Sermons
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/faq">
                    Questions
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/donation">
                    Donate
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contacts">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
